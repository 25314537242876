<template>
    <div class="input-wrapper"
        :style="{
            width: typeof width == 'number' ? `${width}%` : `100%`,
        }"
    >
        <v-text-field 
            :type="type" 
            :name="name" 
            :label="`${label} ${label !== '' && required ? ` *` : ``}`"
            :readonly="readOnly" 
            :disabled="disabled" 
            :maxLength="maxLength" 
            :required="required"
            :autocomplete="autocomplete" 
            :rules="fnName"
            v-model="model"
            density="compact" 
            hide-details="auto"
        flat solo />
        <span v-if="error">{{ errorMsg }}</span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            default: ''
        },
        type: {
            default: 'text'
        },
        name: {
            default: ''
        },
        maxLength: {
            default: 100
        },
        readOnly: {
            default: false
        },
        disabled: {
            default: false
        },
        required: {
            default: false
        },
        width: {
            default: '100%'
        },
        underLine: {
            default: false
        },
        autocomplete: {
            default: false
        },
        fnName: {
            default: ''
        },
        value: {
            default: ''
        },
    },
    data() {
        return {
            error: false,
        }
    },
    watch: {
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.input-wrapper {
    display: flex;
    //margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    ::v-deep .v-input__slot{
        padding:0 !important;
        margin-bottom:5px;
    }
    ::v-deep .v-text-field__details{
        padding:0;
        margin-bottom:0;
    }
    ::v-deep .v-input__control{
        min-height: auto !important;
    }
}
::v-deep input[type=number]::-webkit-inner-spin-button, 
::v-deep input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

</style>
